<template>
    <div>
        <nh-title title="报表管理"></nh-title>
        <div style="display: flex">
            <div><nh-menu active-selected="PatientList"></nh-menu></div>
            <div  class="list-container">
                <div class="search-bar-wrapper">
                    <div class="search-bar">

                    </div>
                </div>
                <el-table class="list-table-container"
                          :data="reportList"
                          stripe>
                    <el-table-column v-if="isAdmin"
                                     label="专员">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.nurseId" clearable placeholder="专员" @change="searchChange">
                                <el-option v-for="item in nurseList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{getNurse(scope.row)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="名称">
                    </el-table-column>
                    <el-table-column
                            label="类型">
                        <template slot="header" slot-scope="scope">
                            <el-select :aa="scope" v-model="searchParams.type" clearable placeholder="类型" @change="searchChange">
                                <el-option v-for="item in typeList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </template>
                        <template slot-scope="scope">
                            {{getTypeName(scope.row)}}
                        </template>
                    </el-table-column>
<!--    时间要减一                -->
<!--                    <el-table-column-->
<!--                            label="时间">-->
<!--                        <template slot-scope="scope">-->
<!--                            {{scope.row.createdTime | formatDate}}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column
                            label="下载">
                        <template slot-scope="scope">
                            <el-link :href="changeDownloadDomain(scope.row.url)">下载</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right;">
                    <el-pagination
                            layout="total,prev,pager,next"
                            :total="total"
                            :page-size="$urlConstant.pageSize"
                            :current-page="currentPage"
                            @current-change="fetchData">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhMenu from "@/components/patient/components/nh-menu";
import StringUtil from "@/common/utils/StringUtil";
export default {
    name: "ReportList",
    components: {NhMenu, NhTitle},
    data() {
        return {
            reportList: [],
            currentPage: 1,
            total: 0,
            totalPages: 0,
            searchParams:{

            },
            nurseList:[],
            typeList: [{
                id:1,name:'日报'
            },{
                id:2,name:'周报'
            },{
                id:3,name:'月报'
            }]
        }
    },
    computed: {
        isAdmin() {
            return this.$sess.getCurrentDoctor().admin
        }
    },
    created() {
        this.fetchData(this.currentPage)
        this.fetchNurses()
    },
    methods:{
        fetchData(index) {
            let url = this.$urlConstant.nuohua.reportListForCurrentUser
            if (this.isAdmin){
                url = this.$urlConstant.nuohua.reportList
            }

            this.$httpUtil.post(StringUtil.stringInject(url, [
                    index-1,
                    this.$urlConstant.pageSize
                ]),
                this.searchParams,
                (responseData) => {
                    this.reportList = responseData.data.content
                    this.total = responseData.data.totalElements
                    this.totalPages = responseData.data.totalPages
                    this.currentPage = index;
                },
                this
            )
        },
        fetchNurses() {
            if (this.isAdmin) {
                this.$httpUtil.get(this.$urlConstant.nuohua.nurseList, (res) => {
                    this.nurseList = res.data
                }, this)
            }
        },
        searchChange() {
            this.fetchData(1)
        },
        getNurse(report) {
            if (report.nurseId) {
                const nurse = this.nurseList.find(nurse=>nurse.id === report.nurseId)
                if (nurse)
                    return nurse.name
            }
            return ''
        },
        getTypeName(report) {
            const t = this.typeList.find(type=>type.id === report.type)
            if (t)
                return t.name
            return ''
        },
        changeDownloadDomain(url) {
            return url.replace('https://yitian-cms.oss-cn-hangzhou.aliyuncs.com','https://oss.jojo.la')
        }
    }
}
</script>

<style scoped>
.search-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}
.search-bar {
    width: 220px;
    margin-left: 5px;
}

.area-operate {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.area-operate img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    object-fit: contain;
}
</style>