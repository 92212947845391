<template>
    <div class="menu-container">
        <div v-if="isAdmin" class="menu-item" @click="handleSelect('PatientList')">
            <div class="menu-item-inner" :class="activeSelected === 'PatientList'?'menu-patient-sel':'menu-patient'"></div>
        </div>
        <div v-if="isAdmin" class="menu-item" @click="handleSelect('NurseList')">
            <div class="menu-item-inner" :class="activeSelected === 'NurseList'?'menu-nurse-sel':'menu-nurse'"></div>
        </div>
        <div v-if="isAdmin" class="menu-item" @click="handleSelect('AreaList')">
            <div class="menu-item-inner" :class="activeSelected === 'AreaList'?'menu-area-sel':'menu-area'"></div>
        </div>
        <div v-if="!isAdmin" class="menu-item" @click="handleSelect('PatientList')">
            <div class="menu-item-inner" :class="activeSelected === 'PatientList'?'menu-patient-sel':'menu-patient'"></div>
        </div>
        <div v-if="!isAdmin" class="menu-item" @click="handleSelect('TaskList')">
            <div class="menu-item-inner" :class="activeSelected === 'TaskList'?'menu-task-sel':'menu-task'"></div>
        </div>
        <div class="menu-item" @click="handleSelect('StatisticList')">
            <div class="menu-item-inner" :class="activeSelected === 'StatisticList'?'menu-statistic-sel':'menu-statistic'"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "nh-menu",
    props: {
        activeSelected: {
            type: String,
            required: true
        }
    },
    computed: {
        isAdmin() {
            return this.$sess.getCurrentDoctor().admin
        }
    },
    methods: {
        handleSelect(key) {
            this.$router.push({name:key})
        }
    },
}
</script>

<style scoped>
.menu-container {
    width: 49px;
    height: calc(100vh - 40px);
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 0 #8A99BD;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.menu-item {
    width: 100%;
    height: 50px;
}
.menu-item img {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.menu-item-inner {
    height: 100%;
    cursor: pointer;
}

.menu-patient-sel {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/60b4dd5cf655aeadffe517008e8f3cb4.png") center/100% no-repeat;
}
.menu-patient {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/cd9b0a16d014ad28a86ff34f9d55c3cb.png") center/100% no-repeat;
}
.menu-patient:hover {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/72cc600cb47bf48b7408e1728c79d2db.png") center/100% no-repeat;
}
.menu-patient:active {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/352e61bf528a8af9b8ffc5bf66512220.png") center/100% no-repeat;
}

.menu-nurse-sel {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/bc49e68a0b232d91e4f984c3af8fbab4.png") center/100% no-repeat;
}
.menu-nurse {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/ecd93bfdf89b0add1293919467487e75.png") center/100% no-repeat;
}
.menu-nurse:hover {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/441d126a3ec20ddb96af4cef2a70e975.png") center/100% no-repeat;
}
.menu-nurse:active {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/af47bd50ed96f4e34a2e7b598f7ee6e4.png") center/100% no-repeat;
}

.menu-area-sel {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/f3f77693038719dd2230a10eb83a6e79.png") center/100% no-repeat;
}
.menu-area {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/a70f17686edb6736d523fd3c7986c604.png") center/100% no-repeat;
}
.menu-area:hover {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/4fe9607d353ba6828ff3289441c8021a.png") center/100% no-repeat;
}
.menu-area:active {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/4a51f0b921b16a492f9df1b50d67c079.png") center/100% no-repeat;
}

.menu-task-sel {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/51e818870a47e02b1d72ad5bceb81562.png") center/100% no-repeat;
}
.menu-task {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/d16182d72517584c1319e00121bc2720.png") center/100% no-repeat;
}
.menu-task:hover {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/d3970cbc080bf7b115aa4a6af439224f.png") center/100% no-repeat;
}
.menu-task:active {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/3d3b4bef722e11d93cae693bddc499dc.png") center/100% no-repeat;
}

.menu-statistic-sel {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/e791801c839d31f567ba89ee00556749.png") center/100% no-repeat;
}
.menu-statistic {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/b2866f70c51d9824c4575d573af2b6f2.png") center/100% no-repeat;
}
.menu-statistic:hover {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/a22303508bdeb15503910f3c48330b80.png") center/100% no-repeat;
}
.menu-statistic:active {
    background: url("https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/1f699c399edbcc7fac4baba51013f193.png") center/100% no-repeat;
}
</style>