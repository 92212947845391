<template>
    <el-dropdown class="title-dropdown">
        <div class="title-img">
            <img src="@/assets/image/home.png">
        </div>
<!--        <div class="title-name">-->
<!--            {{$sess.get('nickname')?$sess.get('nickname'):$sess.get('username')}}-->
<!--        </div>-->
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleSelect('logout')">退出登录</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    export default {
        name: 'logoutHeader',
        data() {
            return {
                // activeIndex: '1'
            }
        },
        methods: {
            handleSelect(key) {
                console.log(key)
                if (key === 'logout') {
                    this.$httpUtil.delete(this.$urlConstant.login.logoutDelete, () => {
                        this.$sess.logout()
                        location.href = process.env.VUE_APP_YSZS_URL
                    }, this)
                } else {
                    this.$router.push({name: key})
                }
            }
        }
    }
</script>

<style scoped>
    .title-dropdown {
        margin-right: 20px;
    }

    .title-img {
        height: 27px;
    }

    .title-img img {
        height: 27px;
        width: 27px;
        object-fit: cover;
    }

    .title-name {
        height: 17px;
        line-height: 17px;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
    }
</style>
